import logout from './logout.js';
import { removeFormError } from '../index.js';

export function loadUserMenu () {
  document.getElementById('lp_drawer_switch_forms').style.display = 'none';
  document.getElementById('forgotPass').style = 'display: none';
  removeFormError();

  const form = document.getElementById('lp_drawer_form');
  form.innerHTML = `
    <button id='logout' class='logout'>Logout</button>
  `;

  addUserMenuEventListeners();
}

function addUserMenuEventListeners () {
  document.getElementById('logout').addEventListener('click', logout);
}
