import eye from '../assets/images/icons/landing_page/eye.svg';
import { fetchPost } from '../api/api.js';
import { showPassword, appendErrorDiv } from './shared.js';
import { initEditor, removeCurrentForm, initialized } from '../index.js';
import { setLocalStorage } from '../assets/helpers/localStorage.js';
import { loadUserMenu } from './userMenu.js';
import { showHeader } from './header.js';
import { handleDisplayDrawer, hideDrawer } from './drawer';
import { getScenes } from '../api/getScenes';
import NoticePopup from '../models/uiPopups/notice';
import Localization from '../assets/localization';
import { Loader } from '../models/Loader';

export function loadLoginFormHTML () {
  const form = document.getElementById('lp_drawer_form');
  form.innerHTML = `
    <label class="lp_drawer_form_label">Sign In</label>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="email">Email</label>
      <input type="text" id="email" name="email" class="lp_drawer_input" placeholder="Email">
    </div>
    <div class="lp_drawer_input_container">
      <label class="lp_drawer_label" for="email">Password</label>
      <input type="password" id="password" name="password" autocomplete="on" class="lp_drawer_input" placeholder="Password">
      <img src=${eye} id='lp_drawer_password_eye' class="lp_drawer_password_eye" alt="show password icon"/>
    </div>
    <button id="login_button" class="submit_button">Sign In</button>
  `;
  document.getElementById('forgotPass').style = 'display: flex';

  addLoginButtonListener();
  addShowPasswordListener();
}

function addLoginButtonListener () {
  const loginButton = document.getElementById('login_button');
  loginButton.addEventListener('click', login);
}

function addShowPasswordListener () {
  const eye = document.getElementById('lp_drawer_password_eye');
  eye.addEventListener('click', showPassword);
}

export let isLoggedIn = false;
export const setIsLoggedIn = (value) => {
  isLoggedIn = value;
};

const loader = new Loader();

function login () {
  loader.show();
  const email = document.getElementById('email').value.toLowerCase();
  const password = document.getElementById('password').value;
  const user = { email: email, password: password };

  fetchPost(user, '/users/loginAll')
    .then(response => {
      if (response.error) {
        appendErrorDiv(response.error.message);
      } else {
        setIsLoggedIn(true);
        setLocalStorage('user', {
          id: response?.data?.user?.id,
          first_name: response?.data?.user?.firstName,
          last_name: response?.data?.user?.lastName,
          email: response?.data?.user?.email.toLowerCase(),
          state: response?.data?.user?.state,
          phone: response?.data?.user?.phone,
          inhouse: response?.data?.inhouse
        });
        if (initialized) {
          showHeader();
          handleDisplayDrawer();
        } else {
          initEditor();
          handleDisplayDrawer();
        }
        removeCurrentForm();
        loadUserMenu();

        getScenes();
      }
    }).catch(async () => {
      const localization = new Localization();
      hideDrawer();
      await new NoticePopup(localization.strings.errorNotice);
    }).finally(() => {
      loader.hide();
    });
}
