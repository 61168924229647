import { config } from '../config/config';
import { isLoggedIn } from '../uiComponents/login';
import logout from '../uiComponents/logout';

/**
 * @param object data
 * @param string route - `example: /users/login`
 */
export function fetchPost (data, route) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const response = fetch(`${config.apiEndpointUrl}${route}`, options)
    .then(response => {
      if (response.status === 401 && isLoggedIn) {
        logout();
      }
      return response.json();
    });

  return response;
}

export function fetchGet (route, id = '') {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const response = fetch(`${config.apiEndpointUrl}${route}${id}`, options)
    .then(response => {
      if (response.status === 401 && isLoggedIn) {
        logout();
      }
      return response.json();
    });

  return response;
}
