import { EdgePiece } from './EdgePiece';
import { Color, TextureLoader, MeshBasicMaterial, Float32BufferAttribute, Mesh, BufferGeometry } from 'three';
import InnerCornerTextureHome from '../../assets/textures/edges/HOME-Edge-Inner.jpg';
import InnerCornerTexturePro from '../../assets/textures/edges/PRO-Edge-Inner.jpg';
export class InnerCorner extends EdgePiece {
  static textureInnerCornerPro = new TextureLoader().load(InnerCornerTexturePro);
  static textureInnerCornerHome = new TextureLoader().load(InnerCornerTextureHome);
  static textureInnerCorner = InnerCorner.textureInnerCornerPro;
  static selectedbaseColor = EdgePiece.defaultEdgeColor;
  static materialInnerCorner = new MeshBasicMaterial({ map: InnerCorner.textureInnerCorner, color: InnerCorner.selectedbaseColor });
  static changedInnerCornerColors = new Set();

  constructor (index, sideLength, thickness, orientationRow = false, positiveDirection = false) {
    super();
    this.name = 'InnerCorner';
    this.tileIndex = index;
    this.orientationRow = orientationRow;
    this.positiveDirection = positiveDirection;
    const textureRatio = 0.1757;

    this.loopedAmount = 0;
    this.peggedAmount = 2;

    const cornerGeom = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          0, 0, 0,
          thickness, 0, thickness,
          sideLength, 0, thickness,
          0, 0, 0,
          sideLength, 0, thickness,
          sideLength, 0, 0,

          0, 0, 0,
          thickness, 0, sideLength,
          thickness, 0, thickness,
          0, 0, 0,
          0, 0, sideLength,
          thickness, 0, sideLength
        ],
        3
      )
    );

    cornerGeom.setAttribute(
      'uv',
      new Float32BufferAttribute(
        [
          0, 1,
          textureRatio, 1 - textureRatio,
          1, 1 - textureRatio,
          0, 1,
          1, 1 - textureRatio,
          1, 1,

          0, 1,
          textureRatio, 0,
          textureRatio, 1 - textureRatio,
          0, 1,
          0, 0,
          textureRatio, 0
        ],
        2
      )
    );

    this.cornerMesh = new Mesh(cornerGeom, InnerCorner.materialInnerCorner);
    this.cornerMesh.tileIndex = index;

    const cornerGeomExtraPart = new BufferGeometry().setAttribute(
      'position',
      new Float32BufferAttribute(
        [
          thickness, 0, thickness,
          sideLength, 0, sideLength,
          sideLength, 0, thickness,

          thickness, 0, thickness,
          thickness, 0, sideLength,
          sideLength, 0, sideLength
        ],
        3
      )
    );

    this.cornerMesh.add(new Mesh(cornerGeomExtraPart, EdgePiece.materialEdgeExtraPart));

    if (positiveDirection) {
      if (!orientationRow) {
        this.cornerMesh.rotation.y = -Math.PI / 2;
        this.cornerMesh.position.setX(sideLength);
        this.loopedAmount = 1;
        this.peggedAmount = 1;
      }
    } else {
      if (orientationRow) {
        this.cornerMesh.rotation.y = Math.PI / 2;
        this.cornerMesh.position.setZ(sideLength);
        this.loopedAmount = 1;
        this.peggedAmount = 1;
      } else {
        this.cornerMesh.rotation.y = Math.PI;
        this.cornerMesh.position.setZ(sideLength);
        this.cornerMesh.position.setX(sideLength);
        this.loopedAmount = 2;
        this.peggedAmount = 0;
      }
    }

    this.add(this.cornerMesh);
  }

  changeColor = (newColor) => {
    this.newColor = newColor;
    this.cornerMesh.material = new MeshBasicMaterial({ map: InnerCorner.textureInnerCorner, color: new Color(newColor) });
    InnerCorner.changedInnerCornerColors.add(this);
  }

  static changeAllInnerCornerColors = (color) => {
    InnerCorner.selectedbaseColor = color;
    InnerCorner.materialInnerCorner.color = new Color(color);
    InnerCorner.changedInnerCornerColors.forEach(corner => {
      corner.cornerMesh.material = InnerCorner.materialInnerCorner;
    });
    InnerCorner.changedInnerCornerColors.clear();
  }

  static changeBaseTexture = (textureType) => {
    InnerCorner.textureInnerCorner = textureType === 'pro' ? InnerCorner.textureInnerCornerPro : InnerCorner.textureInnerCornerHome;
    InnerCorner.materialInnerCorner.map = InnerCorner.textureInnerCorner;
  }
}
