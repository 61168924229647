import { PopupWrapper } from './popupWrapper';
import { createElement } from '../../assets/helpers/common';
import closeSvg from '../../assets/images/icons/close.svg';

export default class Notice extends PopupWrapper {
  constructor (popupText, onNoticeOk, onNoticeClose, parent = window.document.body, noticeOkText, onNoticeOkArgument) {
    super();

    this.parent = parent;
    this.onNoticeOk = onNoticeOk || function () {};
    this.onNoticeClose = onNoticeClose || function () {};
    this.noticeOkText = noticeOkText || this.localization.strings.ok;
    this.onNoticeOkArgument = onNoticeOkArgument;
    this.popup = this.create(popupText);

    // add popup to html parent
    this.parent.appendChild(this.popup);
  }

  create = (popupText) => {
    const popUpContainer = createElement('div', null, 'notice-container');
    const popUp = createElement('div', null, 'notice');
    const popUpClose = createElement('div', null, 'notice-close');
    const closeImg = createElement('img');
    const popUpTextWrapper = createElement('div', null, 'notice-text');
    const popUpOk = createElement('div', null, 'notice-ok');

    closeImg.src = closeSvg;
    popUpOk.innerHTML = this.noticeOkText;
    popUpTextWrapper.innerHTML = popupText;
    popUpContainer.appendChild(popUp);
    popUp.appendChild(popUpClose);
    popUp.appendChild(popUpTextWrapper);
    popUp.appendChild(popUpOk);
    popUpClose.appendChild(closeImg);

    popUpClose.addEventListener('click', this.onCloseClick);
    popUpOk.addEventListener('click', this.onOkClick);

    return popUpContainer;
  }

  onCloseClick = () => {
    this.destroy();
    this.onNoticeClose();
  }

  onOkClick = () => {
    this.destroy();
    this.onNoticeOkArgument ? this.onNoticeOk(this.onNoticeOkArgument) : this.onNoticeOk();
  }

  destroy = () => {
    while (this.popup.firstChild) {
      this.popup.removeChild(this.popup.firstChild);
    }
    this.popup.remove();
  }
}
