import { StartingShapes } from '../../../assets/images/StartingShapes';
import { createElement } from '../../../assets/helpers/common';
import wallIcon from '../../../assets/images/icons/walls.svg';
import padIcon from '../../../assets/images/icons/pad.svg';
import { Panels } from '../Panels';
import { config } from '../../../config/config';
import { World } from '../../../World';
export class ShapeSelect extends Panels {
  constructor (changeNav, selectShape, setNavElVisible) {
    super();
    this.changeNav = changeNav;
    this.selectShape = selectShape;
    this.setNavElVisible = setNavElVisible;
    this.container = createElement('div', 'shapeSelect', 'shapeSelect');
    const shapeContainer = createElement('div', 'shapeContainer', 'shapeContainer');
    shapeContainer.classList.add('shapeContainerTransform');
    this.createShapes(shapeContainer);
    const wallPadSwitch = createElement('div', 'wallPadSwitch', 'wallPadSwitch');
    const switchTitle = createElement('div', 'switchTitle', 'switchTitle');
    switchTitle.innerHTML = this.localization.strings.selectStartingShape;
    const switchLabel = createElement('label', 'switch', 'shapeSwitch');
    switchLabel.innerHTML = `
      <input type="checkbox" id="wallPadBtn">
      <div class="slider">
        <div class="shapeSwitchText">
          <img src="${wallIcon}">
          ${this.localization.strings.walls}
        </div>
        <div class="shapeSwitchText">
          <img src="${padIcon}">
          ${this.localization.strings.pads}
        </div>
      </div>
    `;

    document.body.appendChild(this.container);
    this.container.appendChild(shapeContainer);
    this.container.appendChild(wallPadSwitch);
    wallPadSwitch.appendChild(switchTitle);
    wallPadSwitch.appendChild(switchLabel);
    document.getElementById('wallPadBtn').onclick = this.toggleWallsPads;
  }

  toggleWallsPads = (e) => {
    Array.from(document.getElementsByClassName('svgWallClass')).forEach(wall => {
      wall.classList.toggle('hideWall');
    });
    Array.from(document.getElementsByClassName('svgPadClass')).forEach(pad => {
      pad.classList.toggle('hidePad');
    });
    document.getElementById('pattern4').classList.toggle('notClickable');
    document.getElementById('pattern5').classList.toggle('notClickable');
    document.getElementById('shapeContainer').classList.toggle('shapeContainerTransform');
    this.setNavElVisible(e.currentTarget.checked);
    this.setShape(
      config.startingShapes[0]
    );
  }

  createShapes = (shapeContainer) => {
    let id = 0;
    for (let i = 0; i < 3; i++) {
      const shapeRow = createElement('div', `shapeRow${i}`, 'shapeRow');
      for (let j = 0; j < 2; j++) {
        id = 2 * i + j;
        const shape = createElement('div', `pattern${id}`, 'shape');
        (id === 4 || id === 5) && shape.classList.add('notClickable');
        shape.innerHTML = StartingShapes[id];
        shape.onclick = this.selectStartingShape;
        shapeRow.appendChild(shape);
      }
      shapeContainer.appendChild(shapeRow);
    }
  }

  selectStartingShape = (e) => {
    World.preloader.show();
    let pointsArray = [];
    switch (e.currentTarget.id) {
      case 'pattern0':
        pointsArray = config.startingShapes[0];
        break;
      case 'pattern1':
        pointsArray = config.startingShapes[1];
        break;
      case 'pattern2':
        pointsArray = config.startingShapes[2];
        break;
      case 'pattern3':
        pointsArray = config.startingShapes[3];
        break;
      case 'pattern4':
        pointsArray = config.startingShapes[4];
        break;
      case 'pattern5':
        pointsArray = config.startingShapes[5];
        break;
      default:
        return;
    }
    this.setShape(pointsArray);
    setTimeout(() => {
      this.changeNav(1);
      World.preloader.hide();
    }, 0);
  }

  setShape = (points) => {
    this.selectShape(points);
    this.setNavElVisible(document.getElementById('wallPadBtn').checked);
  }
}
