import { fetchPost } from '../api/api.js';
import { removeCurrentForm } from '../index.js';
import { loadLoginFormHTML, setIsLoggedIn } from './login.js';
import { hideDrawer } from './drawer.js';
import { handleShowHeaderButton } from './header.js';
import { appendErrorDiv } from './shared.js';
import NoticePopup from '../models/uiPopups/notice';
import Localization from '../assets/localization';
import { Loader } from '../models/Loader';

const loader = new Loader();

export default function logout () {
  loader.show();
  fetchPost({}, '/users/logout')
    .then(response => {
      if (response === undefined || response.error) {
        appendErrorDiv('Something went wrong. Please try again!');
      } else {
        setIsLoggedIn(false);
        localStorage.removeItem('user'); // eslint-disable-line
        hideDrawer();
        handleShowHeaderButton();
        removeCurrentForm();
        loadLoginFormHTML();
        document.getElementById('lp_drawer_switch_forms').removeAttribute('style');
      }
    }).catch(async () => {
      const localization = new Localization();
      hideDrawer();
      await new NoticePopup(localization.strings.errorNotice);
    }).finally(() => {
      loader.hide();
    });
}
