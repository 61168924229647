import Localization from './../assets/localization/';
import sweestype1 from '../assets/images/icons/tiles/ribtraxPro.png';
import sweestype2 from '../assets/images/icons/tiles/ribtraxProSmooth.png';
import sweestype3 from '../assets/images/icons/tiles/vinyltraxPro.png';
import sweestype4 from '../assets/images/icons/tiles/diamondtraxHome.png';
import sweestype5 from '../assets/images/icons/tiles/diamondtraxHomeFlex.png';
import sweestype6 from '../assets/images/icons/tiles/ribtraxHomeSmooth.png';
import tileBg1 from './../assets/images/icons/tiles/Ribtrax-Pro-bg.jpg';
import tileBg2 from './../assets/images/icons/tiles/Ribtrax-Smooth-Pro-bg.jpg';
import tileBg3 from './../assets/images/icons/tiles/Vinyltrax-pro-bg.jpg';
import tileBg4 from './../assets/images/icons/tiles/Diamondtrax-Home-bg.jpg';
import tileBg5 from './../assets/images/icons/tiles/Diamondtrax-Home-Flex-bg.jpg';
import tileBg6 from './../assets/images/icons/tiles/ribtraxHomeSmoothBg.svg';
import ribtraxTexture from './../assets/textures/tiles/ribtrax.jpg';
import ribtraxSmoothTexture from './../assets/textures/tiles/ribtraxSmooth.jpg';
import ribtraxSmoothHomeTexture from './../assets/textures/tiles/ribtraxSmoothHome.jpg';
import diamondtraxTexture from './../assets/textures/tiles/diamondtrax.jpg';
import vinyltraxPolishedConcreteTexture from './../assets/textures/tiles/PolishedConcrete.jpg';
import vinyltraxAshPineTexture from './../assets/textures/tiles/vinyltraxAshPine.jpg';
import vinyltraxBlackOakTexture from './../assets/textures/tiles/vinyltraxBlackOak.jpg';
import vinyltraxCarbonFiberTexture from './../assets/textures/tiles/vinyltraxCarbonFiber.jpg';
import vinyltraxLightMapleTexture from './../assets/textures/tiles/vinyltraxLightMaple.jpg';
import vinyltraxReclaimedPineTexture from './../assets/textures/tiles/vinyltraxReclaimedPine.jpg';
import { tilePatterns } from './../assets/svgPatternFiles/tilePatterns';

const localization = new Localization();
const numOfTilesPerSide = 54;
const footToMeter = 0.3048;
const relativeRowIndex2 = numOfTilesPerSide;
const relativeRowIndex3 = numOfTilesPerSide * 2;
const relativeRowIndex4 = numOfTilesPerSide * 3;
const relativeRowIndex5 = numOfTilesPerSide * 4;
const relativeRowIndex6 = numOfTilesPerSide * 5;
const relativeRowIndex7 = numOfTilesPerSide * 6;
const relativeRowIndex8 = numOfTilesPerSide * 7;
const relativeRowIndex9 = numOfTilesPerSide * 8;
const relativeRowIndex10 = numOfTilesPerSide * 9;
const relativeRowIndex11 = numOfTilesPerSide * 10;
const relativeRowIndex12 = numOfTilesPerSide * 11;
const relativeRowIndex13 = numOfTilesPerSide * 12;
const relativeRowIndex14 = numOfTilesPerSide * 13;
const relativeRowIndex15 = numOfTilesPerSide * 14;

// window.location.host

const domainConfig = {
  syncourt: {
    apiEndpointUrl: 'https://api.syncourt.swisstraxfloordesigner.com',
    currencySymbol: '£',
    hasApi: true,
    storeUrl: 'https://store.swisstrax.com',
    storeCartUrl: 'https://store.swisstrax.com/cart/',
    discountCodes: [
      { name: '0%', id: null },
      { name: '15%', id: 'DIRECT-410-515' }
    ],
    variantIds: [
      {
        /* eslint-disable */
        'ffffff': 19970693595190,
        'b0b9bd': 19970694086710,
        '9f9f9f': 19970693791798,
        '7c7c7c': 19970693660726,
        '212121': 19970693726262,
        '65402f': 19970694512694,
        'aa5935': 39541629157566,
        'ddb57f': 19970694479926,
        'fcd900': 19970693857334,
        '72a031': 39541628960958,
        '008175': 39541628895422,
        '225e8f': 39541628862654,
        '002e6b': 19970694545462,
        '6f2e89': 39541628993726,
        'e84b93': 39541629026494,
        'cf102d': 19970693988406,
        'dc4405': 19970694447158,
        '96d700': 39541628928190
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 20389969035318,
        'b0b9bd': 20389969264694,
        '7c7c7c': 20781645430838,
        '212121': 20389969100854,
        '65402f': 20389969592374,
        'ddb57f': 20389969559606,
        'fcd900': 20389969166390,
        '002e6b': 20781645660214,
        'cf102d': 20389969231926,
        'dc4405': 20781646217270
        /* eslint-enable */
      },
      {
        0: 20389973065782,
        1: 20389973098550,
        2: 20389973164086,
        3: 20389973229622,
        4: 20389972967478,
        // 5: 20389973262390
      },
      {
        /* eslint-disable */
        'ffffff': 31882698194998,
        'b0b9bd': 31882698096694,
        '7c7c7c': 31882698129462,
        '212121': 31882698031158,
        '65402f': 31882698358838,
        'ddb57f': 31882698391606,
        'fcd900': 31882698326070,
        '002e6b': 31882698260534,
        'cf102d': 31882697998390,
        'dc4405': 31882698293302
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        '7c7c7c': 37911018602686,
        '212121': 37911018569918
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 42323195527421,
        'b0b9bd': 42323195461885,
        '7c7c7c': 42419591184637,
        '212121': 42323195429117,
        '65402f': 42323195658493,
        'ddb57f': 42323195691261,
        'fcd900': 42323195625725,
        '002e6b': 42323195560189,
        'cf102d': 42323195396349,
        'dc4405': 42323195592957
        /* eslint-enable */
      }
    ],
    edgeVariantIds: {
      /* eslint-disable */
      pro: {
        corners: {
          'ffffff': 20500753874998,
          'cf102d': 20500754235446,
          '212121': 20500754104374,
          '9f9f9f': 20500754169910,
          '7c7c7c': 20500754300982,
          'b0b9bd': 20500754202678,
          '002e6b': 20500754268214,
          'dc4405': 20500754497590,
          'fcd900': 20500753973302,
          '65402f': 20500753940534,
          'ddb57f': 20500754137142
        },
        edgesLooped: {
          'ffffff': 31886251589686,
          'cf102d': 31886251425846,
          '212121': 31886251458614,
          '9f9f9f': 31886251491382,
          '7c7c7c': 31886251524150,
          'b0b9bd': 31886251556918,
          '002e6b': 31886251622454,
          'dc4405': 31886251819062,
          'fcd900': 31886251851830,
          '65402f': 31886251884598,
          'ddb57f': 31886251982902
        },
        edgesPegged: {
          'ffffff': 20388893753398,
          'cf102d': 20388894113846,
          '212121': 20388893982774,
          '9f9f9f': 20388894179382,
          '7c7c7c': 20388894048310,
          'b0b9bd': 20388894081078,
          '002e6b': 20388894146614,
          'dc4405': 20388894277686,
          'fcd900': 20388893851702,
          '65402f': 20388893818934,
          'ddb57f': 20388894015542
        }
      },
      home: {
        corners: {
          'ffffff': 31882724606006,
          '65402f': 31882724671542,
          'fcd900': 31882724704310,
          '212121': 31882724835382,
          'ddb57f': 31882724868150,
          'b0b9bd': 31882724933686,
          'cf102d': 31882724966454,
          '002e6b': 31882724999222,
          '7c7c7c': 31882725031990,
          'dc4405': 31882725130294
        },
        edgesLooped: {
          'ffffff': 20500755578934,
          '65402f': 20500755644470,
          'fcd900': 20500755677238,
          '212121': 20500755808310,
          'ddb57f': 20500755841078,
          'b0b9bd': 20500756004918,
          'cf102d': 20500755939382,
          '002e6b': 20500755972150,
          '7c7c7c': 20500755873846,
          'dc4405': 20500756103222
        },
        edgesPegged: {
          'ffffff': 31882853548086,
          '65402f': 31882853842998,
          'fcd900': 31883463884854,
          '212121': 31882853417014,
          'ddb57f': 31882853941302,
          'b0b9bd': 31882853515318,
          'cf102d': 31882853384246,
          '002e6b': 31882853580854,
          '7c7c7c': 31882853482550,
          'dc4405': 31882853777462
        }
      }
      /* eslint-enable */
    }
  },
  activesport: {
    apiEndpointUrl: 'https://api.activesport.swisstraxfloordesigner.com',
    currencySymbol: '£',
    hasApi: true,
    storeUrl: 'https://store.swisstrax.com',
    storeCartUrl: 'https://store.swisstrax.com/cart/',
    discountCodes: [
      { name: '0%', id: null },
      { name: '15%', id: 'DIRECT-410-515' }
    ],
    variantIds: [
      {
        /* eslint-disable */
        'ffffff': 19970693595190,
        'b0b9bd': 19970694086710,
        '9f9f9f': 19970693791798,
        '7c7c7c': 19970693660726,
        '212121': 19970693726262,
        '65402f': 19970694512694,
        'aa5935': 39541629157566,
        'ddb57f': 19970694479926,
        'fcd900': 19970693857334,
        '72a031': 39541628960958,
        '008175': 39541628895422,
        '225e8f': 39541628862654,
        '002e6b': 19970694545462,
        '6f2e89': 39541628993726,
        'e84b93': 39541629026494,
        'cf102d': 19970693988406,
        'dc4405': 19970694447158,
        '96d700': 39541628928190
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 20389969035318,
        'b0b9bd': 20389969264694,
        '7c7c7c': 20781645430838,
        '212121': 20389969100854,
        '65402f': 20389969592374,
        'ddb57f': 20389969559606,
        'fcd900': 20389969166390,
        '002e6b': 20781645660214,
        'cf102d': 20389969231926,
        'dc4405': 20781646217270
        /* eslint-enable */
      },
      {
        0: 20389973065782,
        1: 20389973098550,
        2: 20389973164086,
        3: 20389973229622,
        4: 20389972967478,
        // 5: 20389973262390
      },
      {
        /* eslint-disable */
        'ffffff': 31882698194998,
        'b0b9bd': 31882698096694,
        '7c7c7c': 31882698129462,
        '212121': 31882698031158,
        '65402f': 31882698358838,
        'ddb57f': 31882698391606,
        'fcd900': 31882698326070,
        '002e6b': 31882698260534,
        'cf102d': 31882697998390,
        'dc4405': 31882698293302
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        '7c7c7c': 37911018602686,
        '212121': 37911018569918
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 42323195527421,
        'b0b9bd': 42323195461885,
        '7c7c7c': 42419591184637,
        '212121': 42323195429117,
        '65402f': 42323195658493,
        'ddb57f': 42323195691261,
        'fcd900': 42323195625725,
        '002e6b': 42323195560189,
        'cf102d': 42323195396349,
        'dc4405': 42323195592957
        /* eslint-enable */
      }
    ],
    edgeVariantIds: {
      /* eslint-disable */
      pro: {
        corners: {
          'ffffff': 20500753874998,
          'cf102d': 20500754235446,
          '212121': 20500754104374,
          '9f9f9f': 20500754169910,
          '7c7c7c': 20500754300982,
          'b0b9bd': 20500754202678,
          '002e6b': 20500754268214,
          'dc4405': 20500754497590,
          'fcd900': 20500753973302,
          '65402f': 20500753940534,
          'ddb57f': 20500754137142
        },
        edgesLooped: {
          'ffffff': 31886251589686,
          'cf102d': 31886251425846,
          '212121': 31886251458614,
          '9f9f9f': 31886251491382,
          '7c7c7c': 31886251524150,
          'b0b9bd': 31886251556918,
          '002e6b': 31886251622454,
          'dc4405': 31886251819062,
          'fcd900': 31886251851830,
          '65402f': 31886251884598,
          'ddb57f': 31886251982902
        },
        edgesPegged: {
          'ffffff': 20388893753398,
          'cf102d': 20388894113846,
          '212121': 20388893982774,
          '9f9f9f': 20388894179382,
          '7c7c7c': 20388894048310,
          'b0b9bd': 20388894081078,
          '002e6b': 20388894146614,
          'dc4405': 20388894277686,
          'fcd900': 20388893851702,
          '65402f': 20388893818934,
          'ddb57f': 20388894015542
        }
      },
      home: {
        corners: {
          'ffffff': 31882724606006,
          '65402f': 31882724671542,
          'fcd900': 31882724704310,
          '212121': 31882724835382,
          'ddb57f': 31882724868150,
          'b0b9bd': 31882724933686,
          'cf102d': 31882724966454,
          '002e6b': 31882724999222,
          '7c7c7c': 31882725031990,
          'dc4405': 31882725130294
        },
        edgesLooped: {
          'ffffff': 20500755578934,
          '65402f': 20500755644470,
          'fcd900': 20500755677238,
          '212121': 20500755808310,
          'ddb57f': 20500755841078,
          'b0b9bd': 20500756004918,
          'cf102d': 20500755939382,
          '002e6b': 20500755972150,
          '7c7c7c': 20500755873846,
          'dc4405': 20500756103222
        },
        edgesPegged: {
          'ffffff': 31882853548086,
          '65402f': 31882853842998,
          'fcd900': 31883463884854,
          '212121': 31882853417014,
          'ddb57f': 31882853941302,
          'b0b9bd': 31882853515318,
          'cf102d': 31882853384246,
          '002e6b': 31882853580854,
          '7c7c7c': 31882853482550,
          'dc4405': 31882853777462
        }
      }
      /* eslint-enable */
    }
  },
  'swisstrax-uk': {
    apiEndpointUrl: 'https://api.swisstrax-uk.co.uk',
    currencySymbol: '£',
    hasApi: true,
    storeUrl: 'https://elitemodularflooring.co.uk',
    storeCartUrl: 'https://elitemodularflooring.co.uk/cart/',
    discountCodes: [
      { name: '0%', id: null },
      { name: '15%', id: 'DIRECT-410-515' }
    ],
    variantIds: [
      {
        /* eslint-disable */
        'ffffff': 41799896924339,
        'b0b9bd': 41799896891571,
        '9f9f9f': 41799896858803,
        '7c7c7c': 41799896826035,
        '212121': 41799896793267,
        '65402f': 41799897317555,
        'aa5935': 41751650205875, // TERRA COTTA
        'ddb57f': 41799897383091,
        'fcd900': 41799897252019,
        '72a031': 37884220899507, // TURF GREEN
        '008175': 37884220637363, // TEAL
        '225e8f': 37884220571827, // ISLAND BLUE
        '002e6b': 41799896957107,
        '6f2e89': 37884220702899, // COSMIC PURPLE
        'e84b93': 37884220735667, // CARNIVAL PINK
        'cf102d': 41799897153715,
        'dc4405': 41799897186483,
        '96d700': 37884220833971, // TECHNO GREEN
        '58adce': 37884220604595 // SKY BLUE
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 41802997301427,
        'b0b9bd': 37767625801907,
        '7c7c7c': 37767625670835,
        '212121': 37767625638067,
        '65402f': 41802997366963,
        'ddb57f': 41802997399731,
        'fcd900': 37767625703603,
        '002e6b': 37767625769139,
        'cf102d': 37767625736371,
        'dc4405': 41802997334195
        /* eslint-enable */
      },
      {
        0: 41767007977651,
        1: 41767007912115,
        2: 41767007944883,
        3: 37767676297395,
        4: 41767008010419,
        5: 37767676100787
      },
      {
        /* eslint-disable */
        'ffffff': 41802973675699,
        'b0b9bd': 41802973642931,
        '7c7c7c': 37721411846323,
        '212121': 37721411682483,
        '65402f': 41802973741235,
        'ddb57f': 41802973774003,
        'fcd900': 41802973708467,
        '002e6b': 37721411813555,
        'cf102d': 37721411780787,
        'dc4405': 41767049134259
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        '7c7c7c': 41836572573875,
        '212121': 41836572541107
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 41812031504563,
        'b0b9bd': 41812031307955,
        '7c7c7c': 41812031373491,
        '212121': 41812031439027,
        'cf102d': 41812031471795,
        '002e6b': 41812031537331,
        'dc4405': 41812031570099,
        'fcd900': 41812031602867,
        '65402f': 41812031635635,
        'ddb57f': 41812031668403
        /* eslint-enable */
      }
    ],
    edgeVariantIds: {
      /* eslint-disable */
      pro: {
        corners: {
          'ffffff': 37723125416115,
          'cf102d': 37723125711027,
          '212121': 37723125285043,
          '9f9f9f': 37723125350579,
          '7c7c7c': 37723125317811,
          'b0b9bd': 37723125383347,
          '002e6b': 37723125448883,
          'dc4405': 37723125743795,
          'fcd900': 37723125612723,
          '65402f': 41767025770675,
          'ddb57f': 41767025803443
        },
        edgesLooped: {
          'ffffff': 41799817429171,
          'cf102d': 41799817167027,
          '212121': 41799816708275,
          '9f9f9f': 41799816970419,
          '7c7c7c': 41799817298099,
          'b0b9bd': 41799817035955,
          '002e6b': 41799817232563,
          'dc4405': 41799816904883,
          'fcd900': 41799817691315,
          '65402f': 41799817494707,
          'ddb57f': 41799817560243
        },
        edgesPegged: {
          'ffffff': 37722545914035,
          'cf102d': 37722545750195,
          '212121': 37722545422515,
          '9f9f9f': 37722545651891,
          '7c7c7c': 37722545815731,
          'b0b9bd': 37722545684659,
          '002e6b': 37722545782963,
          'dc4405': 37722545619123,
          'fcd900': 41766678397107,
          '65402f': 41752674533555,
          'ddb57f': 41752674566323
        }
      },
      home: {
        corners: {
          'ffffff': 41812356464819,
          '65402f': 41812356530355,
          'fcd900': 41812356432051,
          '212121': 41812356300979,
          'ddb57f': 41812356563123,
          'b0b9bd': 41812356399283,
          'cf102d': 41812356333747,
          '002e6b': 41812356595891,
          '7c7c7c': 41812356366515,
          'dc4405': 41812356497587
        },
        edgesLooped: {
          'ffffff': 41802535796915,
          '65402f': 41802535927987,
          'fcd900': 41802535895219,
          '212121': 41802535731379,
          'ddb57f': 41802535960755,
          'b0b9bd': 41802704912563,
          'cf102d': 41802535698611,
          '002e6b': 41802535829683,
          '7c7c7c': 41802535764147,
          'dc4405': 41802535862451
        },
        edgesPegged: {
          'ffffff': 41802545004723,
          '65402f': 41802545103027,
          'fcd900': 41802545168563,
          '212121': 41802544906419,
          'ddb57f': 41802545135795,
          'b0b9bd': 41802544971955,
          'cf102d': 41802544873651,
          '002e6b': 41802545037491,
          '7c7c7c': 41802544939187,
          'dc4405': 41802545070259
        }
      }
      /* eslint-enable */
    }
  },
  localhost: {
    apiEndpointUrl: 'http://localhost:3001',
    currencySymbol: '£',
    hasApi: true,
    storeUrl: 'https://elitemodularflooring.co.uk',
    storeCartUrl: 'https://elitemodularflooring.co.uk/cart/',
    discountCodes: [
      { name: '0%', id: null },
      { name: '15%', id: 'DIRECT-410-515' }
    ],
    variantIds: [
      {
        /* eslint-disable */
        'ffffff': 41799896924339,
        'b0b9bd': 41799896891571,
        '9f9f9f': 41799896858803,
        '7c7c7c': 41799896826035,
        '212121': 41799896793267,
        '65402f': 41799897317555,
        'aa5935': 41751650205875, // TERRA COTTA
        'ddb57f': 41799897383091,
        'fcd900': 41799897252019,
        '72a031': 37884220899507, // TURF GREEN
        '008175': 37884220637363, // TEAL
        '225e8f': 37884220571827, // ISLAND BLUE
        '002e6b': 41799896957107,
        '6f2e89': 37884220702899, // COSMIC PURPLE
        'e84b93': 37884220735667, // CARNIVAL PINK
        'cf102d': 41799897153715,
        'dc4405': 41799897186483,
        '96d700': 37884220833971, // TECHNO GREEN
        '58adce': 37884220604595 // SKY BLUE
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 41802997301427,
        'b0b9bd': 37767625801907,
        '7c7c7c': 37767625670835,
        '212121': 37767625638067,
        '65402f': 41802997366963,
        'ddb57f': 41802997399731,
        'fcd900': 37767625703603,
        '002e6b': 37767625769139,
        'cf102d': 37767625736371,
        'dc4405': 41802997334195
        /* eslint-enable */
      },
      {
        0: 41767007977651,
        1: 41767007912115,
        2: 41767007944883,
        3: 37767676297395,
        4: 41767008010419,
        5: 37767676100787
      },
      {
        /* eslint-disable */
        'ffffff': 41802973675699,
        'b0b9bd': 41802973642931,
        '7c7c7c': 37721411846323,
        '212121': 37721411682483,
        '65402f': 41802973741235,
        'ddb57f': 41802973774003,
        'fcd900': 41802973708467,
        '002e6b': 37721411813555,
        'cf102d': 37721411780787,
        'dc4405': 41767049134259
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        '7c7c7c': 41836572573875,
        '212121': 41836572541107
        /* eslint-enable */
      },
      {
        /* eslint-disable */
        'ffffff': 41812031504563,
        'b0b9bd': 41812031307955,
        '7c7c7c': 41812031373491,
        '212121': 41812031439027,
        'cf102d': 41812031471795,
        '002e6b': 41812031537331,
        'dc4405': 41812031570099,
        'fcd900': 41812031602867,
        '65402f': 41812031635635,
        'ddb57f': 41812031668403
        /* eslint-enable */
      }
    ],
    edgeVariantIds: {
      /* eslint-disable */
      pro: {
        corners: {
          'ffffff': 37723125416115,
          'cf102d': 37723125711027,
          '212121': 37723125285043,
          '9f9f9f': 37723125350579,
          '7c7c7c': 37723125317811,
          'b0b9bd': 37723125383347,
          '002e6b': 37723125448883,
          'dc4405': 37723125743795,
          'fcd900': 37723125612723,
          '65402f': 41767025770675,
          'ddb57f': 41767025803443
        },
        edgesLooped: {
          'ffffff': 41799817429171,
          'cf102d': 41799817167027,
          '212121': 41799816708275,
          '9f9f9f': 41799816970419,
          '7c7c7c': 41799817298099,
          'b0b9bd': 41799817035955,
          '002e6b': 41799817232563,
          'dc4405': 41799816904883,
          'fcd900': 41799817691315,
          '65402f': 41799817494707,
          'ddb57f': 41799817560243
        },
        edgesPegged: {
          'ffffff': 37722545914035,
          'cf102d': 37722545750195,
          '212121': 37722545422515,
          '9f9f9f': 37722545651891,
          '7c7c7c': 37722545815731,
          'b0b9bd': 37722545684659,
          '002e6b': 37722545782963,
          'dc4405': 37722545619123,
          'fcd900': 41766678397107,
          '65402f': 41752674533555,
          'ddb57f': 41752674566323
        }
      },
      home: {
        corners: {
          'ffffff': 41812356464819,
          '65402f': 41812356530355,
          'fcd900': 41812356432051,
          '212121': 41812356300979,
          'ddb57f': 41812356563123,
          'b0b9bd': 41812356399283,
          'cf102d': 41812356333747,
          '002e6b': 41812356595891,
          '7c7c7c': 41812356366515,
          'dc4405': 41812356497587
        },
        edgesLooped: {
          'ffffff': 41802535796915,
          '65402f': 41802535927987,
          'fcd900': 41802535895219,
          '212121': 41802535731379,
          'ddb57f': 41802535960755,
          'b0b9bd': 41802704912563,
          'cf102d': 41802535698611,
          '002e6b': 41802535829683,
          '7c7c7c': 41802535764147,
          'dc4405': 41802535862451
        },
        edgesPegged: {
          'ffffff': 41802545004723,
          '65402f': 41802545103027,
          'fcd900': 41802545168563,
          '212121': 41802544906419,
          'ddb57f': 41802545135795,
          'b0b9bd': 41802544971955,
          'cf102d': 41802544873651,
          '002e6b': 41802545037491,
          '7c7c7c': 41802544939187,
          'dc4405': 41802545070259
        }
      }
      /* eslint-enable */
    }
  }
};

function getDomainConfig() {
    const domainConfigKey = Object.keys(domainConfig).find((domainConfigKey) => window.location.host.includes(domainConfigKey));
    return domainConfig[domainConfigKey];
}

export const config = {
  ...getDomainConfig(),
  performanceMonitor: false,
  performanceMonitorThree: false,
  placeEdgesOnWalls: true,
  pointInWallsTolerance: 0,
  snapPrecision: 0.25,
  numOfTilesPerSide: numOfTilesPerSide,
  footToMeter: footToMeter,
  wallThickness: footToMeter * Math.sqrt(2) * 0.25,
  concreteColor: '#e9e9e9',
  worldColor: '#e9e9e9',
  defaultTileSize: 0.4,
  defaultEdgeSize: 0.07,
  defaultTileColor: '#9f9f9f',
  defaultTileChangeColor: '#cf102d',
  defaultEdgeColor: '#cf102d',
  defaultEdgeChangeColor: '#212121',
  distanceLineColor: '#e5171a',
  uiControls: {
    enabled: true
  },
  specColorsPrice: `Specialty Colors:&nbsp<div style="display: flex"><div style="color: #dc0000">${getDomainConfig().currencySymbol}5.99</div><div style="font-size: 15px; font-weight: 300; margin-top: 5px;">/tile</div></div>`,
  standardColorsPrice: 'Standard Colors',
  allColorPalettePro: [
    { color: '#ffffff', name: 'ARCTIC WHITE' },
    { color: '#b0b9bd', name: 'PEARL SILVER' },
    { color: '#9f9f9f', name: 'PEARL GREY' },
    { color: '#7c7c7c', name: 'SLATE GREY' },
    // { color: '#7d7d7d', name: 'TITANIUM GREY' },
    { color: '#212121', name: 'JET BLACK' },
    { color: '#65402f', name: 'CHOCOLATE BROWN' },
    { color: '#aa5935', name: 'TERRA COTTA' },
    { color: '#ddb57f', name: 'MOCHA JAVA' },
    { color: '#fcd900', name: 'CITRUS YELLOW' },
    { color: '#72a031', name: 'TURF GREEN' },
    { color: '#008175', name: 'TEAL' },
    { color: '#225e8f', name: 'ISLAND BLUE' },
    { color: '#002e6b', name: 'ROYAL BLUE' },
    { color: '#6f2e89', name: 'COSMIC PURPLE' },
    { color: '#e84b93', name: 'CARNIVAL PINK' },
    { color: '#cf102d', name: 'RACING RED' },
    { color: '#dc4405', name: 'TROPICAL ORANGE' },
    { color: '#96d700', name: 'TECHNO GREEN' },
    { color: '#58adce', name: 'SKY BLUE' },

    // viniltrax pattern simulated colors for svg
    { color: '#515151', name: 'BLACK OAK' },
    { color: '#cfc4ad', name: 'LIGHT MAPLE' },
    { color: '#757367', name: 'RECLAIMED PINE' },
    { color: '#414141', name: 'CARBON FIBER' },
    { color: '#a39787', name: 'ASH PINE' },
    { color: '#b6b3b2', name: 'POLISHED CONCRETE' },

    // some colors
    { color: '#f5f7f2', name: 'Chantilly Lace' },
    { color: '#efede2', name: 'Swiss Coffe' },
    { color: '#e4e1d8', name: 'Classic Gray' },
    { color: '#e7e5e0', name: 'Calm' },
    { color: '#dad4c5', name: 'Edgecomb Gray' },
    { color: '#b9bbb7', name: 'Coventry Gray' },
    { color: '#dcd3bd', name: 'Manchester Tan' },
    { color: '#c2d2ca', name: 'Palladian Blue' },
    { color: '#f6e2a5', name: 'Hawthorne Yellow' },
    { color: '#b2b8a3', name: 'Saybrook Sage' },
    { color: '#d2c3a8', name: 'Shaker Beige' },
    { color: '#e9dad5', name: 'Proposal' },
    { color: '#deb774', name: 'Dorset Gold' },
    { color: '#434b56', name: 'Hale Navy' },
    { color: '#a4937d', name: 'Alexandria Beige' },
    { color: '#686763', name: 'Kendall Charcoal' },
    { color: '#8b2829', name: 'Caliente' },
    { color: '#524b59', name: 'Shadow' },
    { color: '#4a4b4c', name: 'Wrought Iron' },
    { color: '#000000', name: 'Black' }

  ],
  edgePiecesColorPalette: {
    pro: [
      '#cf102d',
      '#212121',
      '#9f9f9f',
      '#7c7c7c',
      '#b0b9bd',
      '#ffffff',
      '#002e6b',
      '#dc4405',
      '#65402f',
      '#ddb57f',
      '#fcd900'
    ],
    home: [
      '#cf102d',
      '#212121',
      '#7c7c7c',
      '#b0b9bd',
      '#ffffff',
      '#002e6b',
      '#dc4405',
      '#65402f',
      '#ddb57f',
      '#fcd900'
    ]
  },
  startingShapes: [
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [0, 0, 0],
      [4.94, 0, 0],
      [4.94, 0, 2.54],
      [2.54, 0, 2.54],
      [2.54, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [0, 0, 0],
      [5.34, 0, 0],
      [5.34, 0, 2.14],
      [4.14, 0, 2.14],
      [4.14, 0, 5.34],
      [1.2, 0, 5.34],
      [1.2, 0, 2.14],
      [0, 0, 2.14]
    ],
    [
      [0, 0, 0],
      [4.54, 0, 0],
      [4.54, 0, 5.34],
      [3.2, 0, 5.34],
      [3.2, 0, 2.94],
      [1.34, 0, 2.94],
      [1.34, 0, 5.34],
      [0, 0, 5.34]
    ],
    [
      [1, 0, 0],
      [3.54, 0, 0],
      [3.54, 0, 0.8],
      [4.34, 0, 0.8],
      [4.34, 0, 3.74],
      [3.54, 0, 3.74],
      [3.54, 0, 4.54],
      [1, 0, 4.54],
      [1, 0, 3.74],
      [0.2, 0, 3.74],
      [0.2, 0, 0.8],
      [1, 0, 0.8]
    ],
    [
      [2, 0, 0],
      [3.74, 0, 0],
      [3.74, 0, 0.8],
      [4.54, 0, 0.8],
      [4.54, 0, 1.6],
      [5.34, 0, 1.6],
      [5.34, 0, 3.34],
      [4.54, 0, 3.34],
      [4.54, 0, 4.14],
      [3.74, 0, 4.14],
      [3.74, 0, 4.94],
      [2, 0, 4.94],
      [2, 0, 4.14],
      [1.2, 0, 4.14],
      [1.2, 0, 3.34],
      [0.4, 0, 3.34],
      [0.4, 0, 1.6],
      [1.2, 0, 1.6],
      [1.2, 0, 0.8],
      [2, 0, 0.8]
    ]
  ],
  tiles: [
    {
      id: 0,
      price: `${getDomainConfig().currencySymbol}5.99`,
      priceUnit: '/tile',
      title: localization.strings.tile_1_title,
      desc: localization.strings.tile_1_desc,
      img_src: sweestype1,
      bg_img: tileBg1,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro', // large
      texture: ribtraxTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#9f9f9f', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#9f9f9f',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#aa5935',
        '#ddb57f',
        '#fcd900',
        '#72a031',
        '#008175',
        '#225e8f',
        '#58adce',
        '#002e6b',
        '#6f2e89',
        '#e84b93',
        '#cf102d',
        '#dc4405',
        '#96d700'
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[0])
      }
    },
    {
      id: 1,
      price: '4.99',
      priceUnit: '/tile',
      title: localization.strings.tile_2_title,
      desc: localization.strings.tile_2_desc,
      img_src: sweestype2,
      bg_img: tileBg2,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro',
      texture: ribtraxSmoothTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#b0b9bd', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#ddb57f',
        '#fcd900',
        '#002e6b',
        '#cf102d',
        '#dc4405'
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[1])
      }
    },
    {
      id: 2,
      price: '11.99',
      priceUnit: '/tile',
      title: localization.strings.tile_3_title,
      desc: localization.strings.tile_3_desc,
      img_src: sweestype3,
      bg_img: tileBg3,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_m : localization.strings.tile_dimensions_ft,
      tileType: 'pro',
      texture: vinyltraxBlackOakTexture,
      size: 0.4,
      edgeSize: 0.07,
      textureColorPalette: 2,
      defaultPatternColors: ['#515151', '#cfc4ad', '#757367', '#414141'],
      colorPalette: [
        vinyltraxBlackOakTexture,
        vinyltraxLightMapleTexture,
        vinyltraxReclaimedPineTexture,
        vinyltraxCarbonFiberTexture,
        vinyltraxAshPineTexture,
        vinyltraxPolishedConcreteTexture
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[2])
      },
      mapColorsToPattern: ['#515151', '#cfc4ad', '#757367', '#414141', '#a39787', '#b6b3b2']
    },
    {
      id: 3,
      price: '2.99',
      priceUnit: '/tile',
      title: localization.strings.tile_4_title,
      desc: localization.strings.tile_4_desc,
      img_src: sweestype4,
      bg_img: tileBg4,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_small_m : localization.strings.tile_dimensions_small_ft,
      tileType: 'home', // small
      texture: diamondtraxTexture,
      size: 0.305,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#b0b9bd', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#ddb57f',
        '#fcd900',
        '#002e6b',
        '#cf102d',
        '#dc4405'
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[3])
      }
    },
    {
      id: 4,
      price: '4.99',
      priceUnit: '/tile',
      title: localization.strings.tile_5_title,
      desc: localization.strings.tile_5_desc,
      img_src: sweestype5,
      bg_img: tileBg5,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_small_m : localization.strings.tile_dimensions_small_ft,
      tileType: 'home', // small
      texture: diamondtraxTexture,
      size: 0.305,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#7c7c7c', '#212121', '#7c7c7c', '#212121'],
      colorPalette: [
        '#7c7c7c',
        '#212121'
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[4])
      }
    },
    {
      id: 5,
      price: '2.99',
      priceUnit: '/tile',
      title: localization.strings.tile_6_title,
      desc: localization.strings.tile_6_desc,
      img_src: sweestype6,
      bg_img: tileBg6,
      dimensions: (units) => units === 'metric' ? localization.strings.tile_dimensions_small_m : localization.strings.tile_dimensions_small_ft,
      tileType: 'home', // small
      texture: ribtraxSmoothHomeTexture,
      size: 0.305,
      edgeSize: 0.07,
      textureColorPalette: 0,
      defaultPatternColors: ['#b0b9bd', '#fcd900', '#002e6b', '#cf102d'],
      colorPalette: [
        '#ffffff',
        '#b0b9bd',
        '#7c7c7c',
        '#212121',
        '#65402f',
        '#ddb57f',
        '#fcd900',
        '#002e6b',
        '#cf102d',
        '#dc4405'
      ],
      variantIds: {
        ...(getDomainConfig().variantIds[5])
      }
    }
  ],
  edgeVariantIds: {
    ...(getDomainConfig().edgeVariantIds)
  },
  tilePatterns: [
    { id: 0, svg: tilePatterns[0], colors: 1 },
    { id: 1, svg: tilePatterns[1], colors: 2 },
    { id: 2, svg: tilePatterns[2], colors: 3 },
    { id: 3, svg: tilePatterns[3], colors: 2 },
    { id: 4, svg: tilePatterns[4], colors: 2 },
    { id: 5, svg: tilePatterns[5], colors: 3 },
    { id: 6, svg: tilePatterns[6], colors: 3 },
    { id: 7, svg: tilePatterns[7], colors: 2 },
    { id: 8, svg: tilePatterns[8], colors: 4 }
  ],
  patterns: [
    {
      repeat: false,
      width: 0,
      height: 0,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 2,
      height: 2,
      primaryColorRelativeIndexes: [
        [0],
        [relativeRowIndex2 + 1]
      ],
      secondaryColorRelativeIndexes: [
        [1],
        [relativeRowIndex2]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 3,
      height: 3,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 2],
        [],
        [relativeRowIndex3, relativeRowIndex3 + 2]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1]
      ],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 4, 5, 6, 7, 8, 9],
        [relativeRowIndex2, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 10],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [relativeRowIndex11, relativeRowIndex11 + 10]
      ]
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [1, 2, 3, 7, 8, 9],
        [relativeRowIndex2, relativeRowIndex2 + 4, relativeRowIndex2 + 6, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 4, relativeRowIndex3 + 6, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 4, relativeRowIndex4 + 6, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 4, relativeRowIndex5 + 6, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 4, relativeRowIndex6 + 6, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 4, relativeRowIndex7 + 6, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 4, relativeRowIndex8 + 6, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 4, relativeRowIndex9 + 6, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 4, relativeRowIndex10 + 6, relativeRowIndex10 + 10],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 4, 6, 10],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [relativeRowIndex11, relativeRowIndex11 + 4, relativeRowIndex11 + 6, relativeRowIndex11 + 10]
      ]
    },
    {
      repeat: false,
      width: 11,
      height: 11,
      primaryColorRelativeIndexes: [],
      secondaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 6, 7, 8, 9, 10],
        [relativeRowIndex2, relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 6, relativeRowIndex2 + 7, relativeRowIndex2 + 8, relativeRowIndex2 + 9, relativeRowIndex2 + 10],
        [relativeRowIndex3, relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 6, relativeRowIndex3 + 7, relativeRowIndex3 + 8, relativeRowIndex3 + 9, relativeRowIndex3 + 10],
        [relativeRowIndex4, relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 6, relativeRowIndex4 + 7, relativeRowIndex4 + 8, relativeRowIndex4 + 9, relativeRowIndex4 + 10],
        [relativeRowIndex5, relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 6, relativeRowIndex5 + 7, relativeRowIndex5 + 8, relativeRowIndex5 + 9, relativeRowIndex5 + 10],
        [relativeRowIndex6, relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 6, relativeRowIndex6 + 7, relativeRowIndex6 + 8, relativeRowIndex6 + 9, relativeRowIndex6 + 10],
        [relativeRowIndex7, relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 6, relativeRowIndex7 + 7, relativeRowIndex7 + 8, relativeRowIndex7 + 9, relativeRowIndex7 + 10],
        [relativeRowIndex8, relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 6, relativeRowIndex8 + 7, relativeRowIndex8 + 8, relativeRowIndex8 + 9, relativeRowIndex8 + 10],
        [relativeRowIndex9, relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 6, relativeRowIndex9 + 7, relativeRowIndex9 + 8, relativeRowIndex9 + 9, relativeRowIndex9 + 10],
        [relativeRowIndex10, relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 6, relativeRowIndex10 + 7, relativeRowIndex10 + 8, relativeRowIndex10 + 9, relativeRowIndex10 + 10],
        [relativeRowIndex11, relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 6, relativeRowIndex11 + 7, relativeRowIndex11 + 8, relativeRowIndex11 + 9, relativeRowIndex11 + 10]
      ],
      tertiaryColorRelativeIndexes: [],
      quaternaryColorRelativeIndexes: []
    },
    {
      repeat: true,
      width: 15,
      height: 15,
      primaryColorRelativeIndexes: [
        [],
        [],
        [relativeRowIndex3 + 1, relativeRowIndex3 + 2, relativeRowIndex3 + 5, relativeRowIndex3 + 6, relativeRowIndex3 + 8, relativeRowIndex3 + 10, relativeRowIndex3 + 11, relativeRowIndex3 + 12, relativeRowIndex3 + 13],
        [relativeRowIndex4 + 1, relativeRowIndex4 + 2, relativeRowIndex4 + 5, relativeRowIndex4 + 6, relativeRowIndex4 + 8, relativeRowIndex4 + 10, relativeRowIndex4 + 11, relativeRowIndex4 + 12, relativeRowIndex4 + 13],
        [],
        [],
        [relativeRowIndex7 + 1, relativeRowIndex7 + 2, relativeRowIndex7 + 5, relativeRowIndex7 + 6, relativeRowIndex7 + 8, relativeRowIndex7 + 10, relativeRowIndex7 + 11, relativeRowIndex7 + 12, relativeRowIndex7 + 13],
        [relativeRowIndex8 + 1, relativeRowIndex8 + 2, relativeRowIndex8 + 5, relativeRowIndex8 + 6, relativeRowIndex8 + 8, relativeRowIndex8 + 10, relativeRowIndex8 + 11, relativeRowIndex8 + 12, relativeRowIndex8 + 13],
        [],
        [relativeRowIndex10 + 1, relativeRowIndex10 + 2, relativeRowIndex10 + 5, relativeRowIndex10 + 6, relativeRowIndex10 + 8, relativeRowIndex10 + 10, relativeRowIndex10 + 11, relativeRowIndex10 + 12, relativeRowIndex10 + 13],
        [relativeRowIndex11 + 1, relativeRowIndex11 + 2, relativeRowIndex11 + 5, relativeRowIndex11 + 6, relativeRowIndex11 + 8, relativeRowIndex11 + 10, relativeRowIndex11 + 11, relativeRowIndex11 + 12, relativeRowIndex11 + 13],
        [],
        [],
        [relativeRowIndex14 + 1, relativeRowIndex14 + 2, relativeRowIndex14 + 5, relativeRowIndex14 + 6, relativeRowIndex14 + 8, relativeRowIndex14 + 10, relativeRowIndex14 + 11, relativeRowIndex14 + 12, relativeRowIndex14 + 13],
        [relativeRowIndex15 + 1, relativeRowIndex15 + 2, relativeRowIndex15 + 5, relativeRowIndex15 + 6, relativeRowIndex15 + 8, relativeRowIndex15 + 10, relativeRowIndex15 + 11, relativeRowIndex15 + 12, relativeRowIndex15 + 13]
      ],
      secondaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 1, relativeRowIndex2 + 2, relativeRowIndex2 + 5, relativeRowIndex2 + 6, relativeRowIndex2 + 8, relativeRowIndex2 + 10, relativeRowIndex2 + 11, relativeRowIndex2 + 12, relativeRowIndex2 + 13],
        [relativeRowIndex3 + 3, relativeRowIndex3 + 4, relativeRowIndex3 + 7, relativeRowIndex3 + 9, relativeRowIndex3 + 14],
        [relativeRowIndex4 + 3, relativeRowIndex4 + 4, relativeRowIndex4 + 7, relativeRowIndex4 + 9, relativeRowIndex4 + 14],
        [relativeRowIndex5 + 1, relativeRowIndex5 + 2, relativeRowIndex5 + 5, relativeRowIndex5 + 6, relativeRowIndex5 + 8, relativeRowIndex5 + 10, relativeRowIndex5 + 11, relativeRowIndex5 + 12, relativeRowIndex5 + 13],
        [relativeRowIndex6 + 1, relativeRowIndex6 + 2, relativeRowIndex6 + 5, relativeRowIndex6 + 6, relativeRowIndex6 + 8, relativeRowIndex6 + 10, relativeRowIndex6 + 11, relativeRowIndex6 + 12, relativeRowIndex6 + 13],
        [relativeRowIndex7 + 3, relativeRowIndex7 + 4, relativeRowIndex7 + 7, relativeRowIndex7 + 9, relativeRowIndex7 + 14],
        [relativeRowIndex8 + 3, relativeRowIndex8 + 4, relativeRowIndex8 + 7, relativeRowIndex8 + 9, relativeRowIndex8 + 14],
        [relativeRowIndex9 + 1, relativeRowIndex9 + 2, relativeRowIndex9 + 5, relativeRowIndex9 + 6, relativeRowIndex9 + 8, relativeRowIndex9 + 10, relativeRowIndex9 + 11, relativeRowIndex9 + 12, relativeRowIndex9 + 13],
        [relativeRowIndex10 + 3, relativeRowIndex10 + 4, relativeRowIndex10 + 7, relativeRowIndex10 + 9, relativeRowIndex10 + 14],
        [relativeRowIndex11 + 3, relativeRowIndex11 + 4, relativeRowIndex11 + 7, relativeRowIndex11 + 9, relativeRowIndex11 + 14],
        [relativeRowIndex12 + 1, relativeRowIndex12 + 2, relativeRowIndex12 + 5, relativeRowIndex12 + 6, relativeRowIndex12 + 8, relativeRowIndex12 + 10, relativeRowIndex12 + 11, relativeRowIndex12 + 12, relativeRowIndex12 + 13],
        [relativeRowIndex13 + 1, relativeRowIndex13 + 2, relativeRowIndex13 + 5, relativeRowIndex13 + 6, relativeRowIndex13 + 8, relativeRowIndex13 + 10, relativeRowIndex13 + 11, relativeRowIndex13 + 12, relativeRowIndex13 + 13],
        [relativeRowIndex14 + 3, relativeRowIndex14 + 4, relativeRowIndex14 + 7, relativeRowIndex14 + 9, relativeRowIndex14 + 14],
        [relativeRowIndex15 + 3, relativeRowIndex15 + 4, relativeRowIndex15 + 7, relativeRowIndex15 + 9, relativeRowIndex15 + 14]
      ],
      tertiaryColorRelativeIndexes: [
        [],
        [relativeRowIndex2 + 3, relativeRowIndex2 + 4, relativeRowIndex2 + 7, relativeRowIndex2 + 9, relativeRowIndex2 + 14],
        [],
        [],
        [relativeRowIndex5 + 3, relativeRowIndex5 + 4, relativeRowIndex5 + 7, relativeRowIndex5 + 9, relativeRowIndex5 + 14],
        [relativeRowIndex6 + 3, relativeRowIndex6 + 4, relativeRowIndex6 + 7, relativeRowIndex6 + 9, relativeRowIndex6 + 14],
        [],
        [],
        [relativeRowIndex9 + 3, relativeRowIndex9 + 4, relativeRowIndex9 + 7, relativeRowIndex9 + 9, relativeRowIndex9 + 14],
        [],
        [],
        [relativeRowIndex12 + 3, relativeRowIndex12 + 4, relativeRowIndex12 + 7, relativeRowIndex12 + 9, relativeRowIndex12 + 14],
        [relativeRowIndex13 + 3, relativeRowIndex13 + 4, relativeRowIndex13 + 7, relativeRowIndex13 + 9, relativeRowIndex13 + 14]
      ],
      quaternaryColorRelativeIndexes: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        [relativeRowIndex2],
        [relativeRowIndex3],
        [relativeRowIndex4],
        [relativeRowIndex5],
        [relativeRowIndex6],
        [relativeRowIndex7],
        [relativeRowIndex8],
        [relativeRowIndex9],
        [relativeRowIndex10],
        [relativeRowIndex11],
        [relativeRowIndex12],
        [relativeRowIndex13],
        [relativeRowIndex14],
        [relativeRowIndex15]
      ]
    }
  ]
};
