import ObjectModelControls from '../ObjectModelControls';

export default class SingleTileControlPanel extends ObjectModelControls {
  constructor (onChange, deafultTile = 1, defaultType = 'regular', deafultColor = 'red', units = 'metric') {
    super();
    this.panel = null;
    this.parentElement = this.createTileWrapper();
    this.tiles = this.prepareTiles();
    this.units = units;
    this.colors = this.prepareColors();
    this.onChange = onChange;

    this.selectedTileID = 0;
    this.selectedColorIndex = 0;
    this.selectedColor = this.colors[this.selectedColorIndex];
  }

  prepareColors = () => {
    return [
      '#ffffff', // ARCTIC WHITE
      '#b0b9bd', // PEARL SILVER
      '#9f9f9f', // PEARL GREY
      '#7c7c7c', // SLATE GREY
      '#212121', // JET BLACK
      '#65402f', // CHOCOLATE BROWN
      '#aa5935', // TERRA COTTA
      '#ddb57f', // MOCHA JAVA
      '#fcd900', // CITRUS YELLOW
      '#96d700', // TECHNO GREEN
      '#72a031', // TURF GREEN
      '#008175', // TEAL
      '#225e8f', // ISLAND BLUE
      '#002e6b', // ROYAL BLUE
      '#6f2e89', // COSMIC PURPLE
      '#e84b93', // CARNIVAL PINK
      '#cf102d', // RACING RED
      '#dc4405' // TROPICAL ORANGE
    ];
  }

  prepareTiles = () => {
    return this.config.tiles;
  }

  createTileWrapper = () => {
    return this.createWrappingElement('div', 'singleTileControl');
  }

  create = (panelName, x = 0, y = 0) => {
    !this.panel && (this.panel = this.createNewPanel(panelName, x, y, this.parentElement));
    this.createTileSelectorOption();
    this.createColorOption();
    this.addColorNameForPanel();
    this.addTextDesriptionForPanel();
  }

  createTileSelectorOption = (value, optionName = 'SingleTilesSelectorControl') => {
    this.singleTileOptionName = optionName;
    this.panel.addSingleTileSelector(this.singleTileOptionName, this.onTileChange, this.onPopUpShown, this.tiles, this.selectedTileID);
  }

  createColorOption = (value, optionName = 'SingleTileColorControl') => {
    this.singleTileColorOptionName = optionName;
    this.panel.addColorSelector(this.singleTileColorOptionName, this.onColorChange, 5, this.colors);
  }

  onPopUpShown = (shown) => {
    if (shown) {
      this.parentElement.style.zIndex = 100;
    } else {
      this.parentElement.style.zIndex = 10;
    }
  }

  // hack for title
  addTextDesriptionForPanel = () => {
    const el = this.parentElement;
    const holder = el.querySelector('.qs_main');
    const textDiv = document.createElement('div');
    textDiv.id = 'singleTileControlTitle';
    textDiv.innerHTML = this.localization.strings.singleTile;
    holder.prepend(textDiv);
  }

  addColorNameForPanel = () => {
    const el = this.parentElement;
    const holder = el.querySelector('.qs_main');
    const textDiv = document.createElement('div');
    textDiv.classList.add('single-tile-control-color-name-title');
    textDiv.innerHTML = this.colors[0];
    holder.prepend(textDiv);
  }

  onValueChange = () => {
    const value = {
      tile: this.selectedTileID,
      color: this.selectedColor
    };
    this.onChange(value);
  }

  onTileChange = (tile) => {
    this.selectedTileID = tile;
    this.onValueChange();
  }

  onColorChange = (color) => {
    this.selectedColor = color;
    this.onValueChange();
  }

  getTileSelectorValue = (optionName = 'SingleTilesSelectorControl') => {
    return this.getValue(optionName);
  }

  getValue = (optionName) => {
    return this.panel.getValue(optionName);
  }

  setValue = (value, optionName) => {
    this.panel.setValue(optionName, value);
  }

  setColorValue = (value) => {
    this.setValue(value, this.singleTileColorOptionName);
  }

  /**
   * Set units for
   * @param {*} units metric|imperial
   */
  setUnits = (units) => {
    this.units = units;
  }

  /**
   * Set the tile type shown in modal
   * Call this when pattern changes
   * this will trigger onValueChange event !!!!
   * @param {string} tileType pro|home
   * @param {int} tileIndex [0-4]
   */
  setTileTypeShown = (tileType, tileIndex) => {
    this.panel._controls.SingleTilesSelectorControl.setTileType(tileType, tileIndex);
  }

  /**
   * Change color palete or change texture palete
   * @param {array of strings} palette colors or img src of textures
   * @param {bool} isTexture
   * @param {string} optionName quick settigns option name
   */
  changeColorPalette = (palette, isTexture = false, defaultColors = [], optionName = this.singleTileColorOptionName) => {
    this.panel.changeColorPalette(optionName, palette, isTexture, defaultColors);
  }

  hide = () => {
    this.panel.hide();
  }

  show = () => {
    this.panel.show();
  }

  remove = () => {
    this.panel.destroy();
  }
}
